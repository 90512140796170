import React from 'react';
import { MEDWIKI_NAME } from '../common/common';
import { default_images } from '../common/defaultImages';
import { routeNames } from '../router/constants';

export const generateProps = (data, contentAccessRedirect) => {
  const {
    question,
    image,
    survey_title,
    title,
    type_id,
    survey_id,
    is_locked,
    price,
    user_content_payment
  } = data || {};

  let type =
    data?.type ||
    data?.trending_type ||
    data?.ms_cat_name ||
    data?.con_type ||
    data?.category ||
    'course' ||
    '';
  if (type === 'comp')
    return {
      type: type,
      internalType: 'comp',
      type_id,
      icon: 'flaticon-medwiki',
      title: question || '',
      image: image,
      contentType: MEDWIKI_NAME,
      fallbackImage: default_images.medwiki,
      premiumContent: is_locked,
      price: price,
      userContentPayment: user_content_payment,
      onClick: () => contentAccessRedirect(routeNames.medwiki.detail, type_id, question),
      // specialities: data?.specialities || [],
      categoryName: data?.sub_category || data?.parent_category,
      survey_title: data?.survey_title,
      deeplink: data?.deeplink,
      is_share: data?.is_share,
      mediaType: data?.con_type,
      specialities: data?.specialities_ids_and_names,
      // description:
      //   ' this is description and this is description this is description and this is description ',
      buttonName: 'Learn More' || 'Watch',
      buttonColor: 'primary' || 'secondary'
    };
  else if (type === 'video_archive' || type === 'video')
    return {
      type: type,

      internalType: 'archived_video',
      type_id,
      icon: 'flaticon-clinicalvideo',
      title: question,
      image: image,
      contentType: 'Clinical Video',
      fallbackImage: default_images.clinical,
      premiumContent: is_locked,
      price: price,
      userContentPayment: user_content_payment,
      onClick: () =>
        contentAccessRedirect(
          routeNames.clinicalVideo.detail,
          type_id,
          question
          // is_locked,
          // price,
          // user_content_payment
        ),
      categoryName: data?.sub_category || data?.parent_category,
      specialities: data?.specialities_ids_and_names,

      video: {
        videoDuration: data?.duration,
        playTime: data?.play_time,
        src: data?.src
      },

      mediaType: data?.con_type,
      // survey_title: data?.survey_title,
      deeplink: data?.deeplink,
      is_share: data?.is_share,
      sponsorLogo: data?.sponsor_logo,
      session_doctor_entities: data?.session_doctor_entities,

      doctorCard: data?.session_doctor_entities[0]?.session_doctor_id ? {
        name: data?.session_doctor_entities[0]?.session_doctor_name,
        Department: data?.session_doctor_entities[0]?.DepartmentName,
        image: data?.session_doctor_entities[0]?.session_doctor_image,
        doctorId: data?.session_doctor_entities[0]?.session_doctor_id,
        onClick: () => {
          contentAccessRedirect(
            routeNames.sessions.speaker,
            data?.session_doctor_entities[0]?.session_doctor_id,
            data?.session_doctor_entities[0]?.session_doctor_name
          );
        }
      } : false,
      hide_time: true,
      session_status: data?.session_doctor_entities[0]?.session_doctor_id ? true : false,
      start_datetime: data?.start_datetime, // required
    };
  else if (type === 'gr')
    return {
      type: type,
      specialities: data?.specialities_ids_and_names,
      internalType: 'gr',
      type_id,
      icon: 'flaticon-grandround',
      title: title,
      image: image,
      contentType: 'Grand Round',
      fallbackImage: default_images.gr,
      premiumContent: is_locked,
      price: price,
      userContentPayment: user_content_payment,
      onClick: () =>
        contentAccessRedirect(
          routeNames.grandRound.detail,
          type_id,
          title,
          is_locked,
          price,
          user_content_payment
        )
    };
  else if (type === 'survey' || type === 'quiz')
    return {
      type: type,
      internalType: 'survey',
      survey_id: survey_id,
      type_id: survey_id,
      icon: 'flaticon-polls',
      title: survey_title,
      image: image,
      contentType: 'SPQ',
      fallbackImage: data?.category == 'quiz' ? default_images.quiz : default_images.survey,
      premiumContent: is_locked,
      price: price,
      userContentPayment: user_content_payment,
      onClick: () =>
        contentAccessRedirect(
          routeNames.spq.detail,
          survey_id,
          survey_title
          // is_locked,
          // price,
          // user_content_payment
        ),
      categoryName: data?.category || data?.parent_category,
      survey_title: data?.survey_title,
      deeplink: data?.deeplink,
      is_share: data?.is_share,
      mediaType: data?.con_type,
      specialities: data?.specialities_ids_and_names,
      buttonName: 'Begin',
      buttonColor: 'secondary',
      point: data?.point || '0',
      description: data?.survey_description
    };
  else if (type === 'training' || type === 'course') {
    return {
      type: type,
      internalType: 'training',
      type_id: data?.id,
      icon: 'flaticon-polls',
      title: title,
      image: image,
      contentType: 'Certified Course',
      id: data?.id,
      fallbackImage: default_images.training,
      premiumContent: is_locked,
      price: price,
      userContentPayment: user_content_payment,
      onClick: () =>
        contentAccessRedirect(
          routeNames.course.cover,
          data?.id,
          title
          // is_locked,
          // price,
          // user_content_payment
        ),
      isCertificate: data?.is_certificate,
      isCompleted: data?.is_completed,
      specialities: data?.specialities_ids_and_names,
      categoryName: data?.sub_category || data?.parent_category,
      survey_title: data?.survey_title,
      deeplink: data?.deeplink,
      is_share: data?.is_share,
      mediaType: data?.con_type,
      duration: data?.duration,
      buttonName: 'View Details',
      buttonColor: 'secondary',
      modulesCount: data?.module_Count || 0
    };
  } else if (type == 'session' || type == 'Webinar') {
    return {
      type: type,

      internalType: 'session',
      type_id: data?.session_id,
      icon: 'flaticon-livecme',
      title: data?.session_topic,
      image: data?.cover_image,
      contentType: data?.ms_cat_name ?? 'Live Webinar',
      id: data?.session_id,
      fallbackImage: default_images.session,
      premiumContent: is_locked,
      price: price,
      userContentPayment: user_content_payment,
      onClick: () =>
        contentAccessRedirect(
          routeNames.sessions.detail,
          data?.session_id,
          data?.session_topic
          // is_locked,
          // price,
          // user_content_payment
        ),
      session_doctor_entities: data?.session_doctor_entities,
      // doctor card
      doctorCard: {
        name: data?.session_doctor_entities[0]?.session_doctor_name,
        Department: data?.session_doctor_entities[0]?.DepartmentName,
        image: data?.session_doctor_entities[0]?.session_doctor_image,
        doctorId: data?.session_doctor_entities[0]?.session_doctor_id,
        onClick: () => {
          contentAccessRedirect(
            routeNames.sessions.speaker,
            data?.session_doctor_entities[0]?.session_doctor_id,
            data?.session_doctor_entities[0]?.session_doctor_name
          );
        }
      },
      sponsorLogo: data?.sponsor_logo,
      session_status: data?.session_status,
      start_datetime: data?.start_datetime,
      sponsor_entity: data?.sponsor_entity,
      color: data?.color,
      startSessionCountDown: true,

      // share
      specialities: data?.specialities_ids_and_names,
      categoryName: data?.sub_category || data?.category || data?.ms_cat_name,
      survey_title: data?.survey_title,
      deeplink: data?.deeplink,
      is_share: data?.is_share,
      logo: data?.ms_cat_logo,
      buttonName: 'Attend',
      buttonColor: 'secondary'
    };
  } else if (type == 'epub') {
    return {
      type: type,

      internalType: 'epub',
      type_id: data?.type_id,
      icon: 'flaticon-ebook',
      title: data?.title,
      image: data?.image,
      contentType: 'Epaper',
      id: data?.type_id,
      fallbackImage: default_images.epub,
      premiumContent: is_locked,
      price: price,
      userContentPayment: user_content_payment,
      onClick: () =>
        contentAccessRedirect(
          routeNames.epaper.detail,
          data?.type_id,
          data?.title,
          is_locked,
          price,
          user_content_payment
        ),
      categoryName: data?.sub_category || data?.parent_category,
      specialities: data?.specialities_ids_and_names,
      is_share: data?.is_share,
      deeplink: data?.deeplink,
      buttonName: data?.sub_category === "Alkem Research Articles" ? 'Read More' : null,
      buttonColor: data?.sub_category === "Alkem Research Articles" ? 'secondary' : null,
      imgClass: data?.sub_category === "Alkem Research Articles" ? true : false
    };
  }
};
