import React, { memo } from 'react';
// import '../../../components/ContentSlider/css/contentSlider.scss';
import '../../pages/medwikiV2/css/medwikiLandingV2.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

SwiperCore.use([Pagination, Navigation]);
import { EVENT } from '../../../TrackerFolder/constants/ActionType';

import { isMobile } from 'react-device-detect';
import InView from 'react-intersection-observer';

import { trackEvent } from '../../../components/tracking/UserActions';
import { CARD_VIEW } from '../tracking/constants';
import TrackerWrapper from '../tracking/TrackerWrapper';
import useContentAccessRedirect from '../../common/useContentAccessRedirect';
import { DEMO_USER_PICTURE, reactHtmlParser } from '../../common/common';
import LazyImage from '../CustomLibraries/LazyImage';
import { default_images } from '../../common/defaultImages';
import MedwikiLandingFeaturedLoader from '../../skeleton/medwiki/MedwikiLandingV2/MedwikiLandingFeaturedLoader';
import ShareButton from '../ShareButton';
import { CgArrowLongRight } from '../icons';
import CpdPoint from '../CpdPoint';
import SessionCountDown from '../SessionCountDown';
import useRedirect from '../../common/hooks/useRedirect';
import { routeNames } from '../../router/constants';
import dayjs from 'dayjs';
import NoResult from '../noResult/NoResult';
import { Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { openInNewTab } from '../banner/utils/openInNewTab';

const LandingFeatureCard = ({
  data,
  popularData,
  isLoading,
  extraClass,
  extraClassForPopular,
  redirectPageName,
  page,
  dontShowTags = false,
  noResultMessage = null
}) => {
  const { redirectTo } = useRedirect();

  const { contentAccessRedirect } = useContentAccessRedirect();
  const specialitiesName = (_data) =>
    _data && _data?.specialities?.split(',').length >= 2
      ? _data?.specialities?.split(',').map((_i, index) => (
          <li className="list-inline-item" key={index + 1}>
            {_i}
          </li>
        ))
      : _data?.specialities;

  console.log('test', data);
  if (data?.length === 0 && noResultMessage) {
    return <NoResult mainText={noResultMessage} />;
  }
  return (
    <>
      <div className="medwikiTopLeft pe-0 pe-lg-5 mb-md-0 mb-20 col-12">
        {!isLoading && data && data?.length > 0 ? (
          <>
            <Swiper
              className={`medwikiFeaturedSlide shadow rounded-3 bg-white w-100 position-relative ${extraClass}`}
              slidesPerView={1}
              loop={false}
              pagination={{ clickable: true }}
              // effect={'fade'}
              id="medwiki_landing_feature_card"
            >
              {/* Medwiki landing featured card starts here */}
              {data &&
                data?.length > 0 &&
                data?.slice(0, 3).map((_data, i) => {
                  return (
                    <SwiperSlide
                      className="medwikiFeaturedMain d-flex flex-wrap bg-white h-auto p-3 pb-50 p-md-4 pb-md-4"
                      key={i + 1}
                    >
                      <div className="medwikiFeaturedPic rounded-3 position-relative overflow-hidden mb-20 mb-md-0">
                        <LazyImage
                          src={
                            _data?.image
                              ? _data?.image
                              : _data?.cover_image
                              ? _data?.cover_image
                              : default_images.medwiki
                          }
                          className="object-fit-cover w-100 h-100 float-start"
                          alt="image"
                          fallback_image={default_images.medwiki}
                        />
                        {dontShowTags ? (
                          <></>
                        ) : (
                          <div className=" d-flex justify-content-end align-items-start bg-primary text-white px-4 py-3 position-absolute top-0 rounded-bottom-3 rounded-start-0 fs-5">
                            {page == 'resources' ? 'Upcoming' : 'Featuring'}
                          </div>
                        )}
                      </div>
                      <TrackerWrapper
                        data={_data}
                        type={CARD_VIEW}
                        className="d-flex medwikiFeaturedRight flex-column justify-content-center text-start position-relative z-1 p-0 ps-0 py-md-3 ps-md-20 ps-xxl-5"
                      >
                        {_data?.start_datetime && page !== 'conference' && (
                          <div className="d-flex align-items-center justify-content-between gap-4 mb-4">
                            {_data?.start_datetime && (
                              <div className="text-center border border-light pt-2 pe-2 ps-1 pb-1 d-inline-block rounded position-relative z-3">
                                <SessionCountDown date={_data?.start_datetime} />
                              </div>
                            )}

                            {_data?.sponsorLogo && _data?.sponsorLogo.length > 0 && (
                              <>
                                {_data?.sponsorLogo.length === 1 && (
                                  <div className="crdV1sponser position-absolute rounded m-0 text-center bg-white">
                                    <div className="crdV1sponserIn position-relative bg-white w-100">
                                      <LazyImage
                                        src={_data?.sponsorLogo[0]}
                                        className="position-absolute start-50 top-50 translate-middle mw-100 mh-100 w-auto h-auto"
                                        alt="sponsor logo"
                                        logo
                                      />
                                    </div>
                                  </div>
                                )}
                                {_data?.sponsorLogo?.length > 1 && (
                                  <Swiper
                                    className="crdV1sponser position-absolute rounded m-0 text-center bg-white"
                                    navigation={false}
                                    spaceBetween={0}
                                    slidesPerView={1}
                                    loop={true}
                                    autoplay={{
                                      delay: 2500
                                    }}
                                    effect="fade"
                                  >
                                    {_data?.sponsorLogo?.map((logo, index) => (
                                      <SwiperSlide
                                        className="crdV1sponserIn position-relative bg-white w-100"
                                        key={index + 1}
                                      >
                                        <LazyImage
                                          src={logo}
                                          className="position-absolute start-50 top-50 translate-middle mw-100 mh-100 w-auto h-auto"
                                          alt="sponsor logo"
                                          logo
                                        />
                                      </SwiperSlide>
                                    ))}
                                  </Swiper>
                                )}
                              </>
                            )}
                          </div>
                        )}

                        {page == 'conference' && (
                          <div className="d-flex gap-3 mb-4">
                            {_data?.miniCard &&
                              _data?.miniCard.length > 0 &&
                              _data?.miniCard?.map((_, index) => {
                                return (
                                  <div className="p-3 d-flex flex-fill flex-grow-1 flex-shrink-1 rounded-3 shadow gap-3">
                                    <div
                                      className={`d-inline-flex align-items-center justify-content-center rounded-circle icon-size-36 bg-opacity-25 bg-${_?.color} text-${_?.color}`}
                                    >
                                      <i className={`${_?.icon} fs-3`}></i>
                                    </div>
                                    <div className="d-flex flex-column lh-1 gap-1">
                                      <span className={`text-${_?.color} fw-semibold fs-3`}>
                                        {_?.count || 0}
                                      </span>
                                      <span className="text-black">{_?.title || ''}</span>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        )}

                        <div className="rounded-3 bg-white shadow medwikiFeaturedContent p-0 p-md-20 py-lg-20 px-lg-20 position-relative">
                          <div className="d-flex align-items-center justify-content-between mb-3">
                            <span className="text-black d-inline-block fs-4 gtm_cl_medwiki_landing_featured_speciality">
                              {_data?.sub_type
                                ? _data?.sub_type
                                : _data?.ms_cat_name
                                ? _data?.ms_cat_name
                                : _data?.parent_type}
                            </span>

                            <div className="d-flex align-items-center justify-content-between gap-3">
                              {_data?.start_datetime && page !== 'conference' ? (
                                <h6 className="fs-4 text-dark">
                                  <span>{dayjs(_data?.start_datetime).format('ddd, D MMM')}</span>{' '}
                                  <span className="mx-2">|</span>
                                  {dayjs(_data?.start_datetime).format('hh:mm A')}
                                </h6>
                              ) : (
                                <></>
                              )}
                              {/* todo */}

                              <ShareButton
                                gtmTagVariable="gtm_cl_medwiki_landing_featured_share"
                                buttonText={''}
                                customClass="fs-2 text-black"
                                svgClass="medwiki_landing_featured_share"
                                // id={_data.id}
                                data={{
                                  is_share: _data?.is_share,
                                  title: _data.question,
                                  url: _data.deeplink
                                }}
                              />
                            </div>
                            {/* <FiShare2 customClass="fs-2 text-black" /> */}
                          </div>

                          <h2
                            className="line-clamp-2 fs-3 lh-base fw-medium text-black mb-3 gtm_cl_medwiki_landing_feature_card"
                            onClick={() => {
                              // trackActivityEvent(EVENT, trackEvent('VIEW_DETAILS_CLICK'));
                              contentAccessRedirect(
                                redirectPageName,
                                _data.type_id || _data.session_id,
                                _data.question || _data.session_topic
                                // _data.is_locked,
                                // _data.price,
                                // _data.user_content_payment,
                                // `type=${_data.con_type}`
                              );
                            }}
                          >
                            {reactHtmlParser(_data?.question || _data?.session_topic)}
                          </h2>

                          {(_data?.answer || _data?.session_description) && (
                            <h5
                              className="line-clamp-2 fs-4 text-dark mb-4 gtm_cl_medwiki_landing_feature_card"
                              onClick={() => {
                                // trackActivityEvent(EVENT, trackEvent('VIEW_DETAILS_CLICK'));
                                contentAccessRedirect(
                                  redirectPageName,
                                  _data.type_id || _data.session_id,
                                  _data.question || _data.session_topic
                                  // _data.is_locked,
                                  // _data.price,
                                  // _data.user_content_payment,
                                  // `type=${_data.con_type}`
                                );
                              }}
                            >
                              {reactHtmlParser(_data?.answer || _data?.session_description)}
                            </h5>
                          )}

                          {_data?.session_doctor_entities && page !== 'resources' && (
                            <div
                              className="crdV1DoctorBox text-start w-100 mt-2 mb-3 cursorPointer d-flex"
                              onClick={() => {
                                if (_data?.session_doctor_entities[0]?.session_doctor_id) {
                                  redirectTo(
                                    routeNames.sessions.speaker,
                                    _data?.session_doctor_entities[0]?.session_doctor_id,
                                    _data?.session_doctor_entities[0]?.session_doctor_name
                                  );
                                }
                              }}
                            >
                              <div className="rounded crdV1DoctorBoxIn d-flex justify-content-between align-items-center gap-3 border border-1 border-light w-fit-content ps-1 pe-3 py-1 w-auto">
                                <div className="crdV1DoctorPic rounded-circle position-relative overflow-hidden icon-size-48 flex-shrink-0">
                                  <LazyImage
                                    src={
                                      _data?.session_doctor_entities[0]?.session_doctor_image || ''
                                    }
                                    className="object-fit-cover h-100 w-100 float-start"
                                    alt={
                                      _data?.session_doctor_entities[0]?.session_doctor_image || ''
                                    }
                                    fallback_image={DEMO_USER_PICTURE || ''}
                                  />
                                </div>
                                <div className="crdV1DoctorContent flex-grow-1">
                                  <h4 className="text-black fs-4 line-clamp-1">
                                    {_data?.session_doctor_entities[0]?.session_doctor_name}
                                  </h4>
                                  <p className="d-block text-dark fs-5 line-clamp-1">
                                    {_data?.session_doctor_entities[0]?.DepartmentName}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}

                          {page == 'conference' ? (
                            <div className="d-flex justify-content-between align-items-center">
                              <h6 className="fs-4 text-secondary">
                                <span>{dayjs(_data?.start_datetime).format('MMM D, YYYY')}</span>{' '}
                              </h6>
                              <div className="d-flex justify-content-end gap-3">
                                {_data?.agendaUrl ? (
                                  <button
                                    className="btn btn-sm px-4 py-2 border text-dark border-dark rounded-2 medwikiFeaturedBtn gtm_cl_medwiki_landing_feature_card"
                                    onClick={() => openInNewTab(_data?.agendaUrl)}
                                  >
                                    View Official Agenda
                                  </button>
                                ) : (
                                  <></>
                                )}
                                {_data?.registrationUrl ? (
                                  <button
                                    className="btn btn-sm px-4 py-2 btn-secondary rounded-2 medwikiFeaturedBtn gtm_cl_medwiki_landing_feature_card"
                                    onClick={() => {
                                      openInNewTab(_data?.registrationUrl);
                                    }}
                                  >
                                    Register Now
                                  </button>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          ) : (
                            <button
                              className="btn btn-sm px-4 py-2 btn-secondary ms-auto rounded-2 medwikiFeaturedBtn gtm_cl_medwiki_landing_feature_card d-flex gap-2 align-items-center"
                              onClick={() => {
                                contentAccessRedirect(
                                  redirectPageName,
                                  _data.type_id || _data.session_id,
                                  _data.question || _data.session_topic
                                  // _data.is_locked,
                                  // _data.price,
                                  // _data.user_content_payment,
                                  // `type=${_data.con_type}`
                                );
                                // trackActivityEvent(EVENT, trackEvent('VIEW_DETAILS_CLICK'));
                              }}
                            >
                              {page !== 'contents'
                                ? page == 'resources'
                                  ? 'View'
                                  : 'Attend'
                                : 'Learn More'}
                              <CgArrowLongRight
                                customClass="fs-4 medwiki_landing_feature_card_view_details_icon"
                                gtmTagVariable="gtm_cl_medwiki_landing_feature_card"
                              />
                            </button>
                          )}
                        </div>
                      </TrackerWrapper>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </>
        ) : isLoading ? (
          <MedwikiLandingFeaturedLoader />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default memo(LandingFeatureCard);
