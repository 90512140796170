import React, { useEffect, useState } from 'react';
import LandingFeatureCard from '../../components/herosection/LandingFeatureCard';
import MedwikiRelatedPost from '../medwikiV2/MedwikiDetailsComponents/MedwikiRelatedPost';
import {
  useMedwikiDetails,
  useMedwikiFeatured,
  useMedwikiTopPosts
} from '../../Queries/QueryHooks/medwiki.hook';
import useGetParams from '../../common/hooks/useGetParams';
import useRedirect from '../../common/hooks/useRedirect';
import { useSessionDetails, useSessionFeatured } from '../../Queries/QueryHooks/session.hook';
import { routeNames } from '../../router/constants';
import MedwikiPopular from '../medwikiV2/MedwikiLandingComponents/MedwikiPopular';
import { reactHtmlParser } from '../../common/common';
import dayjs from 'dayjs';

function ConferenceDetailsPage() {
  const { redirectTo } = useRedirect();
  const { id, title } = useGetParams();

  const onSuccess = (response) => {};

  const onError = (error) => {};

  const {
    data: sessionDetailsData,
    isLoading: loader,
    refetch
  } = useSessionDetails(id, onSuccess, onError, !!id);
  const { data: featured_session_data, isLoading: featuredLoader } = useSessionFeatured();
  const [enabled, setEnabled] = useState();
  // const { data: medwikiPopularList, isLoading: popularLoader } = useMedwikiFeatured();
  const {
    data: medwikiPopularList,
    isLoading: popularLoader,
    fetchNextPage,
    hasNextPage
  } = useMedwikiTopPosts(
    id,
    enabled,
    sessionDetailsData?.specialities_ids_and_names?.length > 0
      ? sessionDetailsData?.specialities_ids_and_names?.map((_s) => _s.id)
      : ''
  );

  const speciality_ids =
    sessionDetailsData && sessionDetailsData?.specialities_ids_and_names?.length > 0
      ? sessionDetailsData?.specialities_ids_and_names?.map((_s) => _s.id)
      : '';

  useEffect(() => {
    if (sessionDetailsData) {
      setEnabled(true);
    }
    return () => {
      setEnabled(false);
    };
  }, [sessionDetailsData]);
  const [queryEnabled, setQueryEnabled] = useState(true);
  const [sponserLogo, setSponserLogo] = useState([]);

  console.log('featured_session_data', featured_session_data);

  return (
    <div>
      <div className="w-100 float-start desktopBodyIn">
        <div className="container-fluid">
          {/* <TrackerComponent page_name={pageName} module_page="content" type={PAGE_VIEW} />
          <Seo title={`MediSamvad ${pageName}`} url={window.location.href} />
          <div className="w-100 topBanner">
            <BannerItem position={'top'} page_name={'comp'} />
          </div> */}
          <section className="detailsPageLeft mb-5 mb-lg-0 pe-0 pe-lg-5">
            {/* common for all page */}
            <div className="w-100 float-start medwikiLandingV2">
              <div className="medwikiLandingTop mb-4 position-relative d-flex flex-column flex-lg-row justify-content-between">
                <LandingFeatureCard
                  data={[
                    {
                      cover_image: sessionDetailsData?.cover_image,
                      question: sessionDetailsData?.session_topic,
                      start_datetime: sessionDetailsData?.start_datetime,
                      end_datetime: sessionDetailsData?.end_datetime,
                      miniCard: [
                        {
                          id: 1,
                          title: 'Sessions',
                          count: sessionDetailsData?.session_json?.no_of_sessions,
                          icon: 'flaticon-video-camera',
                          color: 'warning'
                        },
                        {
                          id: 2,
                          title: 'Days',
                          count:
                            dayjs(sessionDetailsData?.end_datetime).diff(
                              dayjs(sessionDetailsData?.start_datetime),
                              'day'
                            ) == 0
                              ? 1
                              : dayjs(sessionDetailsData?.end_datetime).diff(
                                  dayjs(sessionDetailsData?.start_datetime),
                                  'day'
                                ),
                          icon: 'flaticon-calendar',
                          color: 'success'
                        },
                        {
                          id: 3,
                          title: 'Speakers',
                          count: sessionDetailsData?.session_doctor_entities?.length,
                          icon: 'flaticon-stethoscope',
                          color: 'secondary'
                        }
                      ],
                      agendaUrl: sessionDetailsData?.session_json?.agenda_url,
                      registrationUrl: sessionDetailsData?.session_json?.agenda_url,
                      sub_type: 'Resources | Conference',
                      is_share: sessionDetailsData?.is_share,
                      deeplink: sessionDetailsData?.deeplink
                    }
                  ]}
                  dontShowTags={true}
                  isLoading={featuredLoader}
                  redirectPageName={routeNames.sessions.detail}
                  page="conference"
                />
                <MedwikiPopular
                  posts={medwikiPopularList?.pages[0]?.data?.data?.slice(0, 3)}
                  title={'Related Content'}
                  isLoading={false}
                  onExploreMoreClick={() =>
                    redirectTo(
                      routeNames.dashboard.listing,
                      '',
                      '',
                      `&id=${id}&speciality=${speciality_ids}&subtype=related_topics`
                    )
                  }
                  buttonText={'Explore More'}
                />
              </div>
              {sessionDetailsData?.session_description && (
                <div className="col-9">
                  {reactHtmlParser(sessionDetailsData?.session_description)}
                </div>
              )}
              <div className="col-9">
                {sessionDetailsData?.disclaimer && (
                  // <div className="w-100 text-start fs-5 disclaimer mt-5 rounded bg-white position-relative overflow-hidden p-0">
                  //   <h6 className="text-black fs-5">Disclaimer</h6>
                  //   <p>{sessionDetailsData.disclaimer}</p>
                  // </div>
                  <div className="w-100 disclaimer rounded bg-white shadow position-relative overflow-hidden py-20 px-4">
                    <div className="w-100 disclaimerBoxIn fw-medium text-start fs-5 text-black position-relative z-1">
                      <h6 className="fs-4 text-primary text-uppercase fw-semibold mb-2">
                        Disclaimer
                      </h6>
                      <p className="fs-5 fw-normal">{sessionDetailsData.disclaimer}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default ConferenceDetailsPage;
