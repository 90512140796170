import React, { useState, useEffect, useRef } from 'react';
import ShareCard from './CustomLibraries/videoPlayerV1/components/ShareCard';
import { colorDecider } from '../pages/grTraining/utils/utilities';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getSessionCategoryIcon } from '../common/common';

function SpecialitiesPill({
  specialities,
  cardName,
  gtmTagVariable,
  shareClick,
  data,
  is_completed,
  isShare,
  logo,
  contentType
}) {
  // const specialityArray =
  //   specialities && Array.isArray(specialities)
  //     ? specialities
  //     : typeof specialities === 'string'
  //     ? specialities.split(',').map((s) => s.trim())
  //     : [];

  const is_completedShow = is_completed ? true : false;

  console.log('is_completedShow', is_completedShow, is_completed);

  const is_share = isShare === 1 || isShare === true || isShare === '1' ? true : false;

  console.log('isShare', isShare);
  console.log('helllllllllllllllll');
  const handlePillClick = (e) => {
    e.stopPropagation();
  };
  return (
    <div
      onClick={handlePillClick}
      className="crdV1topPart d-flex align-items-center justify-content-between position-relative mb-3 gap-4"
    >
      {logo && contentType && typeof logo === 'string' && logo !== '' ? (
        <h5 className="fs-4 text-black d-flex align-items-center">
          <i className={'text-secondary fs-16 me-3 ' + getSessionCategoryIcon(logo)}></i>
          {contentType}
        </h5>
      ) : (
        <Swiper
          className="clr_v3_swiper bg-transparent  ms-0  clr_v3_formSwitchLabel"
          spaceBetween={5}
          slidesPerView={'auto'}
          loop={false}
          freeMode="true"
          mousewheel={true}
          breakpoints={{
            1: {
              spaceBetween: 5
            },
            768: {
              spaceBetween: 8
            }
          }}
        >
          {cardName && (
            <SwiperSlide
              className="d-flex justify-content-between align-items-center w-auto cursorPointer pb-1"
              key="cardName"
            >
              <span
                className={
                  'badge text-dark border rounded-pill p-3 py-2 fs-5 fw-medium d-flex align-items-center text-capitalize'
                }
              >
                {cardName}
              </span>
            </SwiperSlide>
          )}

          {specialities &&
            Array.isArray(specialities) &&
            specialities?.length > 0 &&
            specialities?.map((speciality, _idx) => (
              <SwiperSlide
                className="d-flex justify-content-between align-items-center w-auto cursorPointer"
                key={_idx + 1}
              >
                <div className="text-black crdV1Speclty d-flex gap-2 flex-grow-1 overflow-hidden">
                  {specialities && (
                    <div className="d-flex gap-2 flex-grow-1 specialityMore_card ">
                      <div className="gap-2 d-flex badge--specialityTagContainer">
                        <span className="badge text-dark border d-flex align-items-center rounded-pill px-3 py-2 fs-5 fw-medium m-0 badge--specialityTag">
                          {speciality?.name}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      )}

      {is_completedShow ? (
        <div className="courseProgress d-flex align-items-center gap-2 flex-shrink-0 ms-auto">
          <div
            className="circleProgress icon-size-20 d-inline-block position-relative overflow-hidden rounded-circle"
            style={{ backgroundColor: colorDecider(is_completedShow) }}
          >
            <div
              className="circleProgressIn position-absolute start-50 w-100 h-100 bg-white bg-opacity-75"
              style={{
                top: `-${
                  is_completed > 100 ? 100 : is_completed > 0 ? Math.round(is_completed) : 0 ?? 0
                }%`
              }}
            ></div>
            <div
              className="circleProgressIn2 position-absolute start-50 w-100 h-100 bg-white bg-opacity-25 mt-1"
              style={{
                top: `-${
                  is_completed > 100 ? 100 : is_completed > 0 ? Math.round(is_completed) : 0 ?? 0
                }%`
              }}
            ></div>
          </div>
          <span className="fs-5 fw-medium" style={{ color: colorDecider(is_completed) }}>
            {is_completed > 100 ? 100 : is_completed > 0 ? Math.round(is_completed) : 0}% Completed
          </span>
        </div>
      ) : (
        <>
          {is_share && (
            <ShareCard
              gtmTagVariable={`gtm_cl_quizCard_share`}
              customClass={`fs-3 text-black ${gtmTagVariable}_quizCard_share`}
              shareClick={shareClick}
              data={{
                title: data?.survey_title,
                url: data?.deeplink,
                is_share: data?.is_share
              }}
            />
          )}
        </>
      )}
    </div>
  );
}

export default SpecialitiesPill;
