import axios from 'axios';
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';
import packageJson from '../../../package.json';
import { deleteCaches, getLocalStorage, setLocalStorage } from '../../common/common';
import pako from 'pako';
const baseURL = process.env.REACT_APP_API_URL;
const authService = process.env.REACT_APP_API_URL;
const apiVersion = packageJson.version;

let accessToken = '';
accessToken = getLocalStorage('accessToken', '') ? getLocalStorage('accessToken', '') : null;

// Object to track disabled APIs
const disabledApis = {};

const axiosInstance = axios.create({
  baseURL,
  headers: {
    Authorization: getLocalStorage('accessToken', ''),
    version: `rjsw ${apiVersion}`
  }
});

const clearLocalStorage = () => {
  deleteCaches();
  const allKeys = Object.keys(localStorage);

  for (const key of allKeys) {
    if (!key.includes('consentAccepted')) {
      localStorage.removeItem(key);
    }
  }
  sessionStorage.clear();
};

const obtainNewAccessToken = async () => {
  try {
    const response = await axios.post(`${authService}auth/getAccessToken`, {
      token: getLocalStorage('refreshToken', '')
    });
    if (response.data.status_code == 200) {
      setLocalStorage('accessToken', response.data.data.token);
      return response.data.data.token;
    }
  } catch (error) {
    try {
      const response = await axios.post(`${authService}auth/getRefreshToken`, {
        token: getLocalStorage('refreshToken', '')
      });
      if (response.data.status_code == 200) {
        setLocalStorage('accessToken', response.data.data.token);
        setLocalStorage('refreshToken', response.data.data.refresh_token);
      }
      return response.data.data.token;
    } catch (error) {
      clearLocalStorage();
      return Promise.reject('loginAgain');
    }
  }
};

const refreshExpiredTokenClosure = () => {
  let isCalled = false;
  let runningPromise = undefined;
  return () => {
    if (isCalled) {
      return runningPromise;
    } else {
      isCalled = true;
      runningPromise = obtainNewAccessToken();
      return runningPromise.finally(() => {
        isCalled = false;
        runningPromise = undefined;
      });
    }
  };
};

const refreshExpiredToken = refreshExpiredTokenClosure();

// Function to disable a specific API
const disableApi = (url) => {
  disabledApis[url] = true;
  console.warn(
    `API calls to ${url} have been disabled due to repeated failures. Please try again later or contact support.`
  );
};

axiosInstance.interceptors.request.use(
  async (req) => {
    // Check if the specific API is disabled
    if (disabledApis[req.url]) {
      return Promise.reject(`API calls to ${req.url} are currently disabled`);
    }

    accessToken = getLocalStorage('accessToken', '') ? getLocalStorage('accessToken', '') : null;
    if (accessToken) {
      req.headers.Authorization = accessToken || '';

      let access = jwtDecode(accessToken);
      let isExpired = dayjs.unix(access.exp).diff(dayjs()) < 1;
      let isgetMethod = req?.method == 'get';
      let utmSource = getLocalStorage('utm_source', '');
      if (isgetMethod && utmSource) {
        let base_url = req.baseURL ? req.baseURL : baseURL;
        let req_url = new URL(base_url + req.url);

        if (!req_url.searchParams.has('utm_source')) {
          req_url.searchParams.append('utm_source', utmSource);
          req.url = req_url.toString();
        }
      }
      if (isExpired) {
        const updatedToken = await refreshExpiredToken();
        req.headers['Authorization'] = updatedToken;
        return req;
      }
      return req;
    }
  },
  async (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    try {
      var bytes = window
        .atob(response.data)
        .split('')
        .map(function (c) {
          return c.charCodeAt(0);
        });

      var inflated = pako.inflate(bytes, { to: 'string' });
      response.data = JSON.parse(inflated);
      return response;
    } catch {
      return response;
    }
  },

  async (error) => {
    const originalRequest = error.config;

    // Add a retry count to the request
    if (typeof originalRequest._retry === 'undefined') {
      originalRequest._retry = 0;
    }

    // Limit retries to 3
    if (originalRequest._retry >= 3) {
      disableApi(originalRequest.url);
      return Promise.reject(
        `Maximum retry attempts reached. API calls to ${originalRequest.url} have been disabled.`
      );
    }

    // Increment retry count
    originalRequest._retry += 1;

    if (error?.response?.status === 401) {
      if (originalRequest.url === `${authService}auth/getAccessToken`) {
        // If getAccessToken fails, try getRefreshToken
        try {
          const response = await axios.post(`${authService}auth/getRefreshToken`, {
            token: getLocalStorage('refreshToken', '')
          });
          if (response.data.status_code == 200) {
            setLocalStorage('accessToken', response.data.data.token);
            setLocalStorage('refreshToken', response.data.data.refresh_token);
            originalRequest.headers['Authorization'] = response.data.data.token;
            return axiosInstance(originalRequest);
          }
        } catch (refreshError) {
          disableApi(`${authService}auth/getAccessToken`);
          disableApi(`${authService}auth/getRefreshToken`);
          return Promise.reject(
            'Failed to refresh token. Token-related API calls have been disabled.'
          );
        }
      } else {
        // For other 401 errors, try to get a new access token
        try {
          const updatedToken = await refreshExpiredToken();
          originalRequest.headers['Authorization'] = updatedToken;
          return axiosInstance(originalRequest);
        } catch (tokenError) {
          // Don't disable the original API here, just return the error
          return Promise.reject('Failed to obtain new token. Please try again later.');
        }
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
