import { electionReducer } from './election.reducer';
import { loginReducer } from './login.reducer';
import { medwikiReducer } from './medwiki.reducer';
import { combineReducers } from 'redux';
import { vaultReducer } from './vault.reducer';
import { grandRoundReducer } from './grandround.reducer';
import { ebookReducer } from './ebook.reducer';
import { profileReducer } from './profile.reducer';
import { CommonReducer } from './commonReducer';
import { channelReducer } from './channelReducer';
import { discussReferReducer } from './discussRefer.reducer';
import { spqReducer } from './spq.reducer';
import { dashboardReducer } from './dashboard.reducer';
import { clinicalVideosReducer } from './clinicalVideo.reducer';
import { shareReducer } from './shareReducer';
import { registrationReducer } from './registrationReducer';
import { sessionReducer } from './session.reducer';
import { bannerReducer } from './banner.reducer';
import { AutoauthReducer } from './autoAuthReducer';
import { certificateReducer } from './certificate.reducer';
import { trackingReducer } from './tracking.reducer';
import { appTourReducer } from './apptour.reducer';
import { appReducer } from './app.reducer';
import { automatedCtaReducer } from './automatedCta.reducer';
import { notificationReducer } from './notification.reducer';
import { settingsReducer } from './settings.reducer';
import { videoPlayerReducer } from './videoPlayer.reducer';

const applicationReducer = combineReducers({
  login: loginReducer,
  vault: vaultReducer,
  grandRound: grandRoundReducer,
  medwiki: medwikiReducer,
  ebook: ebookReducer,
  profile: profileReducer,
  common: CommonReducer,
  channel: channelReducer,
  discussRefer: discussReferReducer,
  spq: spqReducer,
  dashboard: dashboardReducer,
  clinicalVideo: clinicalVideosReducer,
  share: shareReducer,
  registration: registrationReducer,
  session: sessionReducer,
  banner: bannerReducer,
  autoAuth: AutoauthReducer,
  certificate: certificateReducer,
  election: electionReducer,
  tracking: trackingReducer,
  appTour: appTourReducer,
  automatedCta: automatedCtaReducer,
  app: appReducer,
  notification: notificationReducer,
  settings: settingsReducer,
  videoPlayer: videoPlayerReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STORE') {
    state = undefined;
  }
  return applicationReducer(state, action);
};

export default rootReducer;
