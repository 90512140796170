import { automatedCtaConstants } from '../constants/automatedCta.constant';

let initialState = {
  automated_cta: [],
  automated_cta_called: false,
  speciality_data: [],
  is_speciality_called: false,
  settings: null,
  user_action_list: null
};
export const automatedCtaReducer = (state = initialState, action) => {
  switch (action.type) {
    case automatedCtaConstants.AUTOMATED_CTA_SUCCESS:
      return {
        ...state,
        automated_cta: action.payload,
        automated_cta_called: true
      };
    case automatedCtaConstants.SETTINGS:
      return {
        ...state,
        settings: action?.payload ?? null
      };
    case automatedCtaConstants.AUTOMATED_CTA_FAILURE:
      return {
        ...state,
        automated_cta: [],
        automated_cta_called: false
      };
    case automatedCtaConstants.GET_ALL_SPECIALITIES_SUCCESS:
      return {
        ...state,
        speciality_data: action?.payload ?? [],
        is_speciality_called: true
      };
    case automatedCtaConstants.USER_ACTION_LIST:
      return {
        ...state,
        user_action_list: action?.payload ?? []
      };
    case automatedCtaConstants.GET_ALL_SPECIALITIES_FAILURE:
      return {
        ...state,
        speciality_data: [],
        is_speciality_called: false
      };
    default:
      return state;
  }
};
