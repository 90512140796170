import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import doctubeApiInstance from '../../Store/helper/doctubeApiInstance';
import axiosInstance from '../../Store/helper/axiosInstance';
import {
  ATTRIBUTE_LIST,
  CATEGORY_LIST,
  DOCTUBE_CHANNEL_DETAILS,
  DOCTUBE_CLINICS,
  DOCTUBE_DOCTOR_ANALYTICS_METRIX,
  DOCTUBE_DOCTOR_DETAILS,
  DOCTUBE_DOCTOR_INSHIGHT_DETAILS,
  DOCTUBE_DOCTOR_MOST_VIEWED,
  DOCTUBE_DOCTOR_VIDEO_COUNT,
  DOCTUBE_OPEN_TRENDING_CATEGORY,
  DOCTUBE_MANAGE_PLAYLIST,
  DOCTUBE_MANAGE_PLAYLIST_DETAILS,
  DOCTUBE_SEARCH_PLAYLIST,
  DOCTUBE_VIDEO_LISTING,
  LANGUAGE_LIST
} from '../QueryKeys/doctube.key';
import axios from 'axios';
import axiosOpenInstance from '../../Store/helper/axiosOpenInstance';
const cacheTime = 600000;
const staleTime = 30000;
const doctube_base_url = process.env.REACT_APP_API_URL;
export const useDoctubeVideoListing = (
  onSuccess,
  onError,
  filterValue,
  typeValue,
  userType = null,
  enabled = true
) => {
  return useInfiniteQuery(
    [DOCTUBE_VIDEO_LISTING, filterValue, typeValue, userType],
    ({ pageParam = 0 }) =>
      doctubeApiInstance.get(
        `video/list?filter=${
          filterValue === ' ' ? '' : filterValue
        }&type=${typeValue}&limit_to=10&limit_from=${pageParam}${
          userType ? `&user_type=${userType}` : ''
        }`
      ),
    {
      onSuccess,
      onError,
      enabled,
      // select: (response) => response.pages[0].data,
      // staleTime: staleTime,
      cacheTime: cacheTime,
      retry: false,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.length > 0) {
          return allPages.length * 10;
        } else {
          return undefined;
        }
      }
      // onSuccess,
      // select: res => res.data.data
    }
  );
};

export const useDoctubeDoctorDetails = (onSuccess, onError) => {
  return useQuery(DOCTUBE_DOCTOR_DETAILS, () => axiosInstance.get('dashboard/user?response'), {
    onSuccess,
    onError,
    select: (response) => response.data.data.userdetail,
    // staleTime: staleTime,
    cacheTime: cacheTime,
    refetchOnWindowFocus: false
  });
};
export const useDoctubeDoctorVideoCount = (onSuccess, onError) => {
  return useQuery(DOCTUBE_DOCTOR_VIDEO_COUNT, () => doctubeApiInstance.get('video/count'), {
    onSuccess,
    onError,
    // select: (response) => response.data.data.userdetail,
    // staleTime: staleTime,
    cacheTime: cacheTime,
    refetchOnWindowFocus: false
  });
};
export const useDoctubeDoctorInshightDetails = (onSuccess, onError) => {
  return useQuery(DOCTUBE_DOCTOR_INSHIGHT_DETAILS, () => doctubeApiInstance.get('insight/detail'), {
    onSuccess,
    onError,
    select: (response) => response.data.data,
    // staleTime: staleTime,
    cacheTime: cacheTime,
    refetchOnWindowFocus: false
  });
};
export const useDoctubeDoctorMostViewed = (enabled = true, type, onSuccess, onError) => {
  return useQuery(
    [DOCTUBE_DOCTOR_MOST_VIEWED, type],
    () =>
      doctubeApiInstance.get(
        type == 'reel' ? 'insight/mostviewed?type=reel' : 'insight/mostviewed'
      ),
    {
      enabled,
      onSuccess,
      onError,
      select: (response) => response.data.data,
      // staleTime: staleTime,
      cacheTime: cacheTime,
      refetchOnWindowFocus: false
    }
  );
};
export const useDoctubeDoctorAnalyticsMetrix = (onSuccess, onError) => {
  return useQuery(
    DOCTUBE_DOCTOR_ANALYTICS_METRIX,
    () => axiosOpenInstance.get(doctube_base_url + 'analytics/list'),
    {
      onSuccess,
      onError,
      select: (response) => response.data.data,
      cacheTime: cacheTime,
      refetchOnWindowFocus: false,
      retry: false
    }
  );
};
export const useDoctubeOpenTrendingCategory = (onSuccess, onError) => {
  return useQuery(
    DOCTUBE_OPEN_TRENDING_CATEGORY,
    () => axiosOpenInstance.get(doctube_base_url + 'category/list?to=10&from=0'),
    {
      onSuccess,
      onError,
      select: (response) => response.data.data,
      cacheTime: cacheTime,
      refetchOnWindowFocus: false,
      retry: false
    }
  );
};

const changeVideoStatus = (data) => {
  return doctubeApiInstance.post('video/update', data);
};
export const useChangeVideoStatus = (onSuccessCb) => {
  const queryClient = useQueryClient();
  return useMutation(changeVideoStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries([DOCTUBE_VIDEO_LISTING]);
      queryClient.invalidateQueries([DOCTUBE_CHANNEL_DETAILS]);
      queryClient.invalidateQueries([DOCTUBE_DOCTOR_ANALYTICS_METRIX]);
      queryClient.invalidateQueries([DOCTUBE_DOCTOR_INSHIGHT_DETAILS]);
      onSuccessCb();
    }
  });
};
export const useDoctubeChannelDetails = (enabled, onSuccess, onError) => {
  return useQuery(DOCTUBE_CHANNEL_DETAILS, () => doctubeApiInstance.get('channel/detail'), {
    onSuccess,
    onError,
    select: (response) => response?.data?.data,
    staleTime: staleTime,
    cacheTime: cacheTime,
    enabled,
    retry: false,
    refetchOnWindowFocus: false
  });
};
export const useDoctubeClinics = (onSuccess, onError) => {
  return useQuery(DOCTUBE_CLINICS, () => doctubeApiInstance.get('clinic/listing'), {
    onSuccess,
    onError,
    select: (response) => response?.data?.data,
    staleTime: staleTime,
    cacheTime: cacheTime,
    enabled: true,
    retry: false,
    refetchOnWindowFocus: false
  });
};
const submitClinic = (data) => {
  //
  let formdata = new FormData();
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const element = data[key];
      formdata.append(key, element);
    }
  }
  return doctubeApiInstance.post('clinic/add', formdata);
};
const editClinic = (data) => {
  let formdata = new FormData();
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const element = data[key];
      formdata.append(key, element);
    }
  }
  return doctubeApiInstance.post('clinic/edit', formdata);
};
export const useAddClinic = (onSuccess) => {
  const queryClient = useQueryClient();
  return useMutation(submitClinic, {
    onMutate: (newClinic) => {
      const { name, address, city, state, pincode, contact_number } = newClinic;
      const previousData = queryClient.getQueryData(DOCTUBE_CLINICS);
      queryClient.setQueryData(DOCTUBE_CLINICS, (prev) => {
        return {
          ...prev,
          data: {
            ...prev.data,
            data: [...prev.data.data, { name, address, city, state, pincode, contact_number }]
          }
        };
      });
      return { previousData };
    },
    onError: (_err, _obj, context) => {
      queryClient.setQueryData(DOCTUBE_CLINICS, context.previousData);
    },
    onSettled: (_err, _obj, context) => {
      queryClient.invalidateQueries(DOCTUBE_CLINICS);
    },
    onSuccess
  });
};
export const useEditClinic = (onSuccess) => {
  const queryClient = useQueryClient();
  return useMutation(editClinic, {
    onMutate: (newClinic) => {
      const { id, name, address, city, state, pincode, contact_number } = newClinic;
      const previousData = queryClient.getQueryData(DOCTUBE_CLINICS);
      queryClient.setQueryData(DOCTUBE_CLINICS, (prev) => {
        let idxToChange = prev.data.data.findIndex((cl) => cl.id == id);

        prev.data.data[idxToChange] = { id, name, address, city, state, pincode, contact_number };

        return prev;
      });
      return { previousData };
    },
    onError: (_err, _obj, context) => {
      queryClient.setQueryData(DOCTUBE_CLINICS, context.previousData);
    },
    onSettled: (_err, _obj, context) => {
      queryClient.invalidateQueries(DOCTUBE_CLINICS);
    },
    onSuccess
  });
};
const editChannel = (data) => {
  let formdata = new FormData();
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const element = data[key];
      formdata.append(key, element);
    }
  }
  return doctubeApiInstance.post('channel/updatechannel', formdata);
};
export const useEditChannel = (onSuccess) => {
  const queryClient = useQueryClient();
  return useMutation(editChannel, {
    onMutate: (newChannel) => {
      const { channel_name, about, avatar, cover, consult_link } = newChannel;
      const previousData = queryClient.getQueryData(DOCTUBE_CHANNEL_DETAILS);
      queryClient.setQueryData(DOCTUBE_CHANNEL_DETAILS, (prev) => {
        return {
          ...prev,
          data: {
            ...prev.data,
            data: { ...prev.data.data, channel_name, about, avatar, cover, consult_link }
          }
        };
      });
      return { previousData };
    },
    onError: (_err, _obj, context) => {
      queryClient.setQueryData(DOCTUBE_CHANNEL_DETAILS, context.previousData);
    },
    onSettled: (_err, _obj, context) => {
      queryClient.invalidateQueries(DOCTUBE_CHANNEL_DETAILS);
    },
    onSuccess
  });
};
//EDIT_CHANNEL
export const useCategoryList = (onSuccess, onError) => {
  return useQuery(CATEGORY_LIST, () => doctubeApiInstance.get('category/list'), {
    onSuccess,
    onError,
    select: (response) => response?.data?.data,
    staleTime: staleTime,
    cacheTime: cacheTime,
    enabled: true,
    retry: false,
    refetchOnWindowFocus: false
  });
};
export const useAttributeList = (onSuccess, onError) => {
  return useQuery(ATTRIBUTE_LIST, () => doctubeApiInstance.get('attributenew/list'), {
    onSuccess,
    onError,
    select: (response) => response?.data?.data,
    staleTime: staleTime,
    cacheTime: cacheTime,
    enabled: true,
    retry: false,
    refetchOnWindowFocus: false
  });
};
export const useLanguageList = (onSuccess, onError) => {
  return useQuery(LANGUAGE_LIST, () => doctubeApiInstance.get('language/list'), {
    onSuccess,
    onError,
    select: (response) => response?.data?.data,
    staleTime: staleTime,
    cacheTime: cacheTime,
    enabled: true,
    retry: false,
    refetchOnWindowFocus: false
  });
};

// ============================== doctube playlist===================

export const useDoctubeManagePlaylist = (onSuccess, onError, list_id = '') => {
  return useQuery(
    DOCTUBE_MANAGE_PLAYLIST,
    () => doctubeApiInstance.get('video/playlist' + (list_id ? `?list_id=${list_id}` : '')),
    {
      onSuccess,
      onError,
      select: (response) => response?.data?.data,
      // staleTime: staleTime,
      cacheTime: cacheTime,
      refetchOnWindowFocus: false
    }
  );
};

// export const useDoctubeSearchPlaylist = (onSuccess, onError) => {
//   return useQuery(
//     DOCTUBE_SEARCH_PLAYLIST,
//     () => doctubeApiInstance.get('video/playlistvideosearch'),
//     {
//       onSuccess,
//       onError,
//       select: (response) => response?.data?.data,
//       // staleTime: staleTime,
//       cacheTime: cacheTime,
//       refetchOnWindowFocus: false
//     }
//   );
// };

export const useDoctubeManagePlaylistDetails = (list_id, onSuccess, onError) => {
  return useInfiniteQuery(
    [DOCTUBE_MANAGE_PLAYLIST_DETAILS, list_id],
    ({ pageParam = 0 }) =>
      doctubeApiInstance.get(`video/playlistdetail?list_id=${list_id}&to=10&from=${pageParam}`),
    {
      onSuccess,
      onError,
      // select: (response) => response?.data?.data,
      cacheTime: cacheTime,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.length > 0) {
          return allPages.length * 10;
        } else {
          return undefined;
        }
      }
    }
  );
};
export const useDoctubeSearchPlaylist = (word = '', enabled = true, onSuccess, onError) => {
  return useInfiniteQuery(
    [DOCTUBE_SEARCH_PLAYLIST, word],
    ({ pageParam = 0 }) =>
      doctubeApiInstance.get(
        `video/playlistvideosearch?to=10&from=${pageParam}` + (word ? `&word=${word}` : '')
      ),
    {
      enabled,
      onSuccess,
      onError,
      cacheTime: cacheTime,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.length >= 10) {
          return allPages.length * 10;
        } else {
          return undefined;
        }
      }
    }
  );
};

const addPlaylist = (data) => {
  return doctubeApiInstance.post('video/addplaylist', data);
};
export const useAddPlaylist = (onSuccessAddPlaylistCb) => {
  const queryClient = useQueryClient();
  return useMutation(addPlaylist, {
    onSuccess: (result) => {
      onSuccessAddPlaylistCb(result);
      queryClient.invalidateQueries([DOCTUBE_MANAGE_PLAYLIST_DETAILS]);
      queryClient.invalidateQueries([DOCTUBE_MANAGE_PLAYLIST]);
    }
  });
};
const addVideo = (data) => {
  return doctubeApiInstance.post('video/playlistaddvideo', data);
};
export const useAddVideoToPlaylist = (onSuccessAddVideosCb) => {
  const queryClient = useQueryClient();
  return useMutation(addVideo, {
    onSuccess: (result) => {
      onSuccessAddVideosCb(result);
      queryClient.invalidateQueries([DOCTUBE_MANAGE_PLAYLIST_DETAILS]);
      queryClient.invalidateQueries([DOCTUBE_MANAGE_PLAYLIST]);
    }
  });
};
const editPlaylist = (data) => {
  return doctubeApiInstance.post('video/playlistedit', data);
};
export const useEditPlaylist = (onSuccessEditCb) => {
  const queryClient = useQueryClient();
  return useMutation(editPlaylist, {
    onSuccess: (result) => {
      queryClient.invalidateQueries([DOCTUBE_MANAGE_PLAYLIST_DETAILS]);
      queryClient.invalidateQueries([DOCTUBE_MANAGE_PLAYLIST]);
      onSuccessEditCb(result);
    }
  });
};
const deletePlaylist = (data) => {
  return doctubeApiInstance.post('video/deleteplaylist', data);
};
export const useDeletePlaylist = (onSuccessCb) => {
  const queryClient = useQueryClient();
  return useMutation(deletePlaylist, {
    onSuccess: (result) => {
      queryClient.invalidateQueries([DOCTUBE_MANAGE_PLAYLIST]);
      queryClient.invalidateQueries([DOCTUBE_CHANNEL_DETAILS]);
      onSuccessCb(result);
    }
  });
};
const deleteVideos = (data) => {
  return doctubeApiInstance.post('video/deletevideo', data);
};
export const useDeleteVideo = (onSuccessDeleteCb) => {
  const queryClient = useQueryClient();
  return useMutation(deleteVideos, {
    onSuccess: (result) => {
      queryClient.invalidateQueries([DOCTUBE_MANAGE_PLAYLIST_DETAILS]);
      queryClient.invalidateQueries([DOCTUBE_MANAGE_PLAYLIST]);
      onSuccessDeleteCb(result);
    }
  });
};
