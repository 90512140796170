import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { generateUtmExt } from '../../common/common';
import axiosInstance from '../../Store/helper/axiosInstance';
import { DASHBOARD_ALL_DATA } from '../QueryKeys/dashboard.key';
import {
  SESSION_RESERVED,
  SESSION_FEATURED,
  SESSION_UPCOMING,
  SESSION_MASTER_DOCTOR,
  SESSION_DETAILS,
  MASTER_DOCTOR_DETAILS,
  MASTER_UPCOMING,
  MASTER_PREVIOUS,
  MASTER_QUERIES,
  LIVE_SESSION_LIST,
  SESSION_RESERVED_INFINITE,
  SESSION_MASTER_DOCTOR_INFINITE,
  SESSION_CLOSED,
  LEADERBOARD
} from '../QueryKeys/session.key';
import { toast } from 'react-toastify';
import { GET_ALL_CHANNEL_DATA } from '../QueryKeys/channel.key';
import useFeedInitialData from '../utils/useFeedInitialData';
import dayjs from 'dayjs';

const staleTime = 300000;
const cacheTime = 600000;
export const useSessionFeatured = (onSuccess, onError, type) => {
  return useQuery(
    SESSION_FEATURED,
    () =>
      axiosInstance.get(
        `knwlgmastersessionnew/upcoming_mastersession_featured?from=0&to=5${type ? `&type=${type}` : ''
        }`
      ),
    {
      refetchOnWindowFocus: false,
      onSuccess,
      onError,
      select: (response) => response.data.data,
      staleTime: staleTime,
      cacheTime: cacheTime
    }
  );
};

export const useLeaderBoard = (onSuccess, onError) => {
  return useQuery(LEADERBOARD, () => axiosInstance.get('userpoints/listpoints'), {
    refetchOnWindowFocus: false,
    select: (response) => response.data.data,
    staleTime: staleTime,
    cacheTime: cacheTime
  });
};

export const useSessionUpcoming = (onSuccess, onError, type, featured) => {
  return useQuery(
    SESSION_UPCOMING,
    () =>
      axiosInstance.get(
        `knwlgmastersessionnew/upcomingmastersessionslider?from=0&to=10${type ? `&type=${type}` : ''
        }${featured ? `&featured=true` : `&featured=false`}`
      ),
    {
      refetchOnWindowFocus: false,
      onSuccess,
      onError,
      select: (response) => response.data.data,
      // staleTime: staleTime,
      cacheTime: cacheTime
    }
  );
};
export const useSessionReserved = (onSuccess, onError) => {
  return useQuery(
    SESSION_RESERVED,
    () => axiosInstance.get('knwlgmastersessionnew/bookedmastersession?from=0&to=10'),
    {
      refetchOnWindowFocus: false,
      onSuccess,
      onError,
      select: (response) => response.data.data,
      // staleTime: staleTime,
      cacheTime: cacheTime
    }
  );
};

export const useSessionUpcomingCalender = (onSuccess, onError) => {
  return useQuery(
    SESSION_UPCOMING,
    () => axiosInstance.get(`knwlgmastersessionnew/conferrence_calender_list`),
    {
      refetchOnWindowFocus: false,
      onSuccess,
      onError,
      select: (response) => response.data.data,
      // staleTime: staleTime,
      cacheTime: cacheTime
    }
  );
};

export const useSessionClosed = (type = "nonfeatured", enabled, onSuccess, onError) => {
  return useQuery(
    [SESSION_CLOSED, type],
    () => axiosInstance.get('/knwlgmastersessionnew/closedmastersessions?from=0&to=10&type=' + type),
    {
      refetchOnWindowFocus: false,
      onSuccess,
      onError,
      select: (response) => response.data.data,
      // staleTime: staleTime,
      cacheTime: cacheTime,
      enabled: enabled
    }
  );
};

export const useInfiniteReservedSessions = (enabled, onSuccess, onError) => {
  return useInfiniteQuery(
    SESSION_RESERVED_INFINITE,
    ({ pageParam = 0 }) =>
      axiosInstance.get(`knwlgmastersessionnew/bookedmastersession?from=${pageParam}&to=10`),
    {
      refetchOnWindowFocus: false,
      onSuccess,
      enabled,
      onError,
      select: (data) => ({
        ...data,
        pages: data.pages.map((x) => x.data.data)
      }),
      staleTime: staleTime,
      cacheTime: cacheTime,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.length > 0) {
          return allPages.length * 10;
        } else {
          return undefined;
        }
      }
    }
  );
};
export const useSessionMasterDoctor = (onSuccess, onError) => {
  return useQuery(
    SESSION_MASTER_DOCTOR,
    () => axiosInstance.get('knwlgmastersessionnew/featuredDoctorsList?from=0&to=10'),
    {
      refetchOnWindowFocus: false,
      onSuccess,
      onError,
      select: (response) => response.data.data,
      staleTime: staleTime,
      cacheTime: cacheTime
    }
  );
};
export const useSessionDoctors = (enabled, onSuccess, onError) => {
  return useInfiniteQuery(
    SESSION_MASTER_DOCTOR_INFINITE,
    ({ pageParam = 0 }) =>
      axiosInstance.get(`knwlgmastersessionnew/featuredDoctorsList?from=${pageParam}&to=30`),
    {
      refetchOnWindowFocus: false,
      onSuccess,
      enabled,
      onError,
      select: (data) => ({
        ...data,
        pages: data.pages.map((x) => x.data.data)
      }),
      staleTime: staleTime,
      cacheTime: cacheTime,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.length == 30) {
          return allPages.length * 30;
        } else {
          return undefined;
        }
      }
    }
  );
};
const fetchSession = ({ queryKey }) => {
  const id = queryKey[1];
  return axiosInstance.get(
    `knwlgmastersessionnew/sessiondetail_new?session_id=${id}${generateUtmExt()}`
  );
};
export const useSessionDetails = (session_id, onSuccess, onError, queryEnabled) => {
  const queryClient = useQueryClient();
  return useQuery([SESSION_DETAILS, session_id], fetchSession, {
    initialData: () => {
      const sessionFeatured = queryClient
        .getQueryData(SESSION_FEATURED)
        ?.data?.data?.find((_s) => _s.session_id == session_id);
      const sessionUpcoming = queryClient
        .getQueryData(SESSION_UPCOMING)
        ?.data?.data?.find((_s) => _s.session_id == session_id);
      const sessionReserved = queryClient
        .getQueryData(SESSION_RESERVED)
        ?.data?.data?.find((_s) => _s.session_id == session_id);
      const sessionDashboard = queryClient
        .getQueryData(DASHBOARD_ALL_DATA)
        ?.data?.data?.find((_m) => _m.session_id == session_id);

      const [sessionChannel, sessionUiV3Dashboard] = useFeedInitialData('session', session_id);
      const session =
        sessionFeatured ||
        sessionReserved ||
        sessionUpcoming ||
        sessionDashboard ||
        sessionChannel ||
        sessionUiV3Dashboard;
      if (session) {
        return { data: { data: [session] } };
      } else {
        return undefined;
      }
    },
    select: (response) => {
      return {
        ...response.data.data[0],
        session_json: response.data.data[0]?.session_json
          ? JSON.parse(response.data.data[0]?.session_json)
          : {}
      };
    },
    onSuccess,
    onError,
    enabled: queryEnabled,
    refetchOnWindowFocus: false
  });
};
const reserveSession = (data) => {
  const { type, parser } = data;
  console.log(data);
  let post_url = '';
  if (type == 'submit') {
    post_url = 'knwlgmastersessionnew/submitquery';
  } else {
    post_url = 'knwlgmastersessionnew/editquery';
  }
  return axiosInstance.post(post_url, parser);
};
export const useReserveSession = (reservation_type, session_id, onSuccessCb) => {
  const queryClient = useQueryClient();

  return useMutation(reserveSession, {
    onMutate: async (obj) => {
      if (reservation_type == 'submit') {
        // toast.info('Please wait while we are processing your request');
        onSuccessCb(reservation_type, {
          data: {
            participation_id: ''
          }
        });
      }
    },
    onSettled: () => {
      // queryClient.invalidateQueries([SESSION_DETAILS, session_id]);
    },
    onSuccess: (response) => {
      // toast.success(response?.data?.message);
      onSuccessCb(reservation_type, response.data, 'success');
      // console.log('MUTATE RESPOBSE', response.data);
    }
  });
};

//knwlgmastersessionnew/featuredDoctorsList?from=${limit}&to=${to}
export const useMasterDoctorData = (doctor_id, onSuccess, onError, enabled) => {
  const queryClient = useQueryClient();
  return useQuery(
    [MASTER_DOCTOR_DETAILS, doctor_id],
    () => axiosInstance.get(`sessionDoc/featuredDoctorsDetail/${doctor_id}`),
    {
      initialData: () => {
        const masterDoctor =
          queryClient.getQueryData(SESSION_MASTER_DOCTOR)?.data?.data &&
            queryClient.getQueryData(SESSION_MASTER_DOCTOR)?.data?.data?.length > 0
            ? queryClient
              .getQueryData(SESSION_MASTER_DOCTOR)
              ?.data?.data?.find((_s) => _s.sessions_doctors_id == doctor_id)
            : [];
        if (masterDoctor) {
          return { data: { data: [masterDoctor] } };
        } else {
          return undefined;
        }
      },
      select: (response) => response.data.data[0],
      onSuccess,
      onError,
      enabled,
      refetchOnWindowFocus: false
    }
  );
};
export const useUpcomingSessionByDoctor = (doctor_id, enabled) => {
  return useQuery(
    [MASTER_UPCOMING, doctor_id],
    () =>
      axiosInstance.get(`sessionDoc/upcoming_sessions?session_doc_id=${doctor_id}&from=0&to=10`),
    {
      select: (response) => response.data.data,
      refetchOnWindowFocus: false,
      enabled,
      cacheTime: cacheTime,
      staleTime: staleTime
    }
  );
};
export const useClosedSessionByDoctor = (doctor_id, enabled) => {
  return useQuery(
    [MASTER_PREVIOUS, doctor_id],
    () => axiosInstance.get(`sessionDoc/closed_sessions?session_doc_id=${doctor_id}&from=0&to=10`),
    {
      select: (response) => response.data.data,
      refetchOnWindowFocus: false,
      enabled,
      cacheTime: cacheTime,
      staleTime: staleTime
    }
  );
};
export const useQueriesByDoctor = (doctor_id, enabled) => {
  return useQuery(
    [MASTER_QUERIES, doctor_id],
    () => axiosInstance.get(`sessionDoc/get_question_featured_doctor?id=${doctor_id}`),
    {
      select: (response) => response.data.data,
      enabled,
      cacheTime: cacheTime,
      staleTime: staleTime,
      refetchOnWindowFocus: false
    }
  );
};

export const useGetLiveSessionByUser = (onSuccess, onError) => {
  return useQuery(
    LIVE_SESSION_LIST,
    () => axiosInstance.get('knwlgmastersessionnew/live_sessions'),
    {
      refetchOnWindowFocus: false,
      onSuccess,
      onError,
      select: (response) => response.data.data,
      staleTime: staleTime,
      cacheTime: cacheTime
    }
  );
};

// SPECIALITY HOOKS

const saveLike = (data) => {
  return axiosInstance.post('knwlg/save_like', data);
};

export const useAddSpecialityCMELike = (type_id) => {
  const queryClient = useQueryClient();

  return useMutation(saveLike, {
    onMutate: async (obj) => {
      console.log('RQ MUTATION SAVELIKE', obj);
      const queryKeysArr = [SPECIALITY_SESSION, type_id];
      const previousData = queryClient.getQueryData(queryKeysArr);

      queryClient.setQueryData(queryKeysArr, (oldQueryData) => {
        return {
          ...oldQueryData,
          data: {
            ...oldQueryData.data,
            data: {
              ...oldQueryData.data.data,
              rating: oldQueryData.data.data.myrating
                ? oldQueryData.data.data.rating - 1
                : oldQueryData.data.data.rating + 1,
              myrating: !oldQueryData.data.data.myrating
            }
          }
        };
      });
      return { previousData };
    },
    onError: (_err, _obj, context) => { },
    onSettled: () => {
      queryClient.invalidateQueries([SPECIALITY_SESSION, type_id]);
    }
  });
};
